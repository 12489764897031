import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { lowercase, uppercase, numbers, special } from "./utils/characters";
import { useState } from "react";
function App() {
  const [passwordLength, setpasswordLength] = useState(26);
  const [password, setPassword] = useState("");
  const [includeUpperCase, setIncludeUpperCase] = useState(false);
  const [includeLowerCase, setIncludeLowerCase] = useState(false);
  const [includeNumbers, setIncludeNumbers] = useState(false);
  const [includeSpecial, setIncludeSpecial] = useState(false);

  const copyPasswordToClipboard = () => {
    if (password === "") {
      displayToast("No password to copy!", true);
      return;
    }
    navigator.clipboard.writeText(password);
    displayToast("Password Copied!");
  };

  const displayToast = (message, isError = false) => {
    if (isError) {
      toast.error(message);
      return;
    }
    toast.success(message);
  };

  const generateCharacterList = () => {
    let charcterList = "";
    if (includeLowerCase) {
      charcterList += lowercase;
    }
    if (includeNumbers) {
      charcterList += numbers;
    }
    if (includeSpecial) {
      charcterList += special;
    }
    if (includeUpperCase) {
      charcterList += uppercase;
    }
    return charcterList;
  };

  const generatePassword = () => {
    if (isValid()) {
      displayToast("Please check atleast 1 checkbox.", true);
      return;
    }

    if (isValidPasswordLength()) {
      displayToast("Please enter length between 8 and 26.", true);
      return;
    }

    const charactersList = generateCharacterList();

    let generatedPassword = "";
    const charactersListLength = charactersList.length;
    for (let i = 0; i < passwordLength; i++) {
      const charactersListIdx = Math.floor(
        Math.random() * charactersListLength
      );
      generatedPassword += charactersList.charAt(charactersListIdx);
    }
    setPassword(generatedPassword);
    displayToast("Password Generated!");
  };

  const isValidPasswordLength = () => {
    if (passwordLength > 26 || passwordLength < 8) {
      return true;
    }
    return false;
  };

  const isValid = () => {
    if (
      !includeLowerCase &&
      !includeUpperCase &&
      !includeNumbers &&
      !includeSpecial
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="container">
      <div className="card">
        <h1 className="heading">Password Generator</h1>
        <div className="password-container">
          <div
            className="password"
            onClick={copyPasswordToClipboard}
            data-text="Click to copy!"
          >
            {password}
          </div>
        </div>

        <div className="form-group">
          <label className="label">Password Length</label>
          <input
            type="number"
            value={passwordLength}
            onChange={(e) => setpasswordLength(e.target.value)}
            className="password-length"
            min="8"
            max="26"
          />
        </div>

        <div className="form-group">
          <label className="label">Uppercase</label>
          <input
            type="checkbox"
            className="btn-checkbox"
            defaultChecked={includeUpperCase}
            onChange={(e) => setIncludeUpperCase(!includeUpperCase)}
          />
        </div>

        <div className="form-group">
          <label className="label">Lowercase</label>
          <input
            type="checkbox"
            className="btn-checkbox"
            defaultChecked={includeLowerCase}
            onChange={(e) => setIncludeLowerCase(!includeLowerCase)}
          />
        </div>

        <div className="form-group">
          <label className="label">Numbers</label>
          <input
            type="checkbox"
            className="btn-checkbox"
            defaultChecked={includeNumbers}
            onChange={(e) => setIncludeNumbers(!includeNumbers)}
          />
        </div>

        <div className="form-group">
          <label className="label">Special</label>
          <input
            type="checkbox"
            className="btn-checkbox"
            defaultChecked={includeSpecial}
            onChange={(e) => setIncludeSpecial(!includeSpecial)}
          />
        </div>

        <div className="btn-container">
          <button className="btn" onClick={generatePassword}>
            Generate
          </button>
          <button className="btn" onClick={() => setPassword("")}>
            Clear
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        theme="dark"
      />
    </div>
  );
}

export default App;
